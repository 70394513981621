import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useLayoutEffect, useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Headline, { HeadlineType } from '../components/headline';
import Slideshow, { SlideshowType } from '../components/slideshow';
import TextImage, { TextImageType } from '../components/textImage';
import HeroImage from '../components/heroImage';
import { WpLinkType } from '../types/WpLinkType';
import TextCentered from '../components/textCentered';
import RoomPresentation, {
  RoomPresentationType,
} from '../components/roomPresentation';

type ContentType =
  | (HeadlineType & {
      contentType: 'Template_Story_Storydata_Content_Headline';
    })
  | (SlideshowType & {
      contentType: 'Template_Story_Storydata_Content_Slideshow';
    })
  | (TextImageType & {
      contentType: 'Template_Story_Storydata_Content_TextImage';
    });

type Props = {
  data: {
    pageData: {
      title: string;
      template: {
        storyData: {
          heroImage: {
            gatsbyImage: IGatsbyImageData;
          };
          content: ContentType[];
          roomPresentation: {
            blockLeft: RoomPresentationType;
            blockRight: RoomPresentationType;
          };
          footer: {
            headline: string;
            text: string;
            button: WpLinkType;
          };
        };
      };
    };
    seoData: {
      seo: {
        metaDescription: string;
        metaKeywords: string;
      };
    };
  };
};

const Story = ({ data: { pageData, seoData } }: Props) => {
  const heroImage =
    pageData?.template?.storyData?.heroImage?.gatsbyImage || null;
  const content = pageData?.template?.storyData?.content || [];
  const footer = pageData?.template?.storyData?.footer || null;
  const roomPresentation =
    pageData?.template?.storyData?.roomPresentation || null;
  let textToggleCount = 0;
  const [initFlag, setInitFlag] = useState(false);

  useLayoutEffect(() => {
    setInitFlag(true);
  }, []);

  return (
    <Layout>
      <Seo {...seoData.seo} title={pageData.title} />
      {heroImage && <HeroImage alt={pageData.title} image={heroImage} />}
      {content.map((item, index) => {
        if (item.contentType === 'Template_Story_Storydata_Content_Headline') {
          return <Headline key={index}>{item.headline}</Headline>;
        }
        if (item.contentType === 'Template_Story_Storydata_Content_Slideshow') {
          return (
            <Slideshow key={index} slides={item.slides} initFlag={initFlag} />
          );
        }
        if (item.contentType === 'Template_Story_Storydata_Content_TextImage') {
          return (
            <TextImage
              key={index}
              {...item}
              imagePosition={textToggleCount++ % 2 ? 'left' : 'right'}
              initFlag={initFlag}
            />
          );
        }
        return null;
      })}
      {roomPresentation && (
        <RoomPresentation {...roomPresentation} initFlag={initFlag} />
      )}
      <TextCentered variant="green" {...footer} />
    </Layout>
  );
};

export default Story;

export const pageQuery = graphql`
  query StoryQuery($id: String!) {
    pageData: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Story {
          storyData {
            heroImage {
              gatsbyImage(
                width: 1440
                height: 450
                layout: FULL_WIDTH
                fit: COVER
              )
            }
            content {
              ... on WpTemplate_Story_Storydata_Content_Headline {
                contentType: fieldGroupName
                headline
              }
              ... on WpTemplate_Story_Storydata_Content_Slideshow {
                contentType: fieldGroupName
                slides {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                  altText
                }
              }
              ... on WpTemplate_Story_Storydata_Content_TextImage {
                contentType: fieldGroupName
                variant
                headline
                showHxiLogo
                text
                button {
                  title
                  url
                  target
                }
                image {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                  altText
                }
              }
            }
            roomPresentation {
              blockLeft {
                headline
                text
                images {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                }
                button {
                  title
                  url
                  target
                }
              }
              blockRight {
                headline
                text
                images {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                }
                button {
                  title
                  url
                  target
                }
              }
            }
            footer {
              headline
              text
              button {
                title
                url
                target
              }
            }
          }
        }
      }
    }
    seoData: wpPage(id: { eq: $id }) {
      seo {
        metaDescription
        metaKeywords
      }
    }
  }
`;
