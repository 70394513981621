import React from 'react';
import { Link as ReachLink } from 'gatsby';
import {
  Box,
  BoxProps,
  Button,
  Container,
  Heading,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';
import { WpLinkType } from '../types/WpLinkType';

type ButtonVariant = 'whiteToBlack' | 'whiteToOrange' | 'blackToOrange';
type Props = {
  variant: 'white' | 'green' | 'orange';
  headline?: string;
  text?: string;
  button?: WpLinkType;
};

const TextCentered = (props: Props) => {
  const variantProps: BoxProps = {};
  let buttonVariant: ButtonVariant = 'whiteToBlack';
  if (props.variant === 'white') {
    buttonVariant = 'blackToOrange';
    variantProps.color = 'black';
    variantProps.background = 'white';
    variantProps.marginTop = { base: '80px', md: '120px' };
    variantProps.marginBottom = { base: '80px', md: '120px' };
  } else if (props.variant === 'green') {
    variantProps.color = 'white';
    variantProps.background = 'brand.green';
    variantProps.paddingTop = { base: '80px', md: '120px' };
    variantProps.paddingBottom = { base: '80px', md: '120px' };
  } else if (props.variant === 'orange') {
    variantProps.color = 'white';
    variantProps.background = 'brand.orange';
    variantProps.paddingTop = { base: '80px', md: '120px' };
    variantProps.paddingBottom = { base: '80px', md: '120px' };
  }

  return (
    <Box {...variantProps}>
      <Container>
        <SimpleGrid {...gridProps}>
          {props.headline && (
            <Heading {...headlineProps}>{props.headline}</Heading>
          )}
          {props.text && (
            <Box
              {...textProps}
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          )}
          {props.button && (
            <Box {...buttonContainerProps}>
              <Button
                as={ReachLink}
                to={props.button.url}
                target={props.button.target}
                variant={buttonVariant}
              >
                {props.button.title}
              </Button>
            </Box>
          )}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const gridProps: SimpleGridProps = {
  columns: { md: 12 },
  columnGap: '40px',
};
const headlineProps: BoxProps = {
  fontSize: { base: '48px', md: '2xl' },
  lineHeight: 'shorter',
  gridColumn: { md: '3 / span 8' },
  textAlign: 'center',
  marginBottom: { base: '32px', md: '10px' },
};
const textProps: BoxProps = {
  fontSize: { md: 'md' },
  gridColumn: { md: '4 / span 6' },
  textAlign: 'center',
};
const buttonContainerProps: BoxProps = {
  gridColumn: { md: '4 / span 6' },
  marginTop: '24px',
  textAlign: 'center',
};

export default TextCentered;
